// session storage names
export const SessionStorageKey = Object.freeze({
    CreditRange: 'Credit_Range',
    DealerPrefixOverride: 'Dealer_Prefix_Override',
    ExperimentCache: 'Experiment_Cache',
    HidePrequalBannerAlert: 'Hide_Prequal_Banner_Alert',
    MockOverride: 'Mock_Override',
    SearchMFEEnabled: 'Search_MFE_Enabled',
    SRPURL: 'SRP_URL',
    ShowExpiredPQModal: 'Show_Expired_PQ_Modal',
    ShowroomC1UserLocation: 'Showroom_C1_UserLocation',
    FinanceFilters: 'Finance_Filters'
});
export const StorageKey = Object.freeze({
    VisitorId: 'Visitor_Id',
    LeadId: 'Lead_Id'
});
const isSessionStorageAvailable = () => {
    let isAvailable = false;
    if (typeof sessionStorage !== undefined) {
        try {
            const key = 'isAvailable';
            const value = 'true';
            sessionStorage.setItem(key, value);
            isAvailable = sessionStorage.getItem(key) === value;
            sessionStorage.removeItem(key);
        }
        catch (e) {
            return false;
        }
    }
    return isAvailable;
};
export const removeSessionStorageValue = (key) => {
    const isStoreAvailable = isSessionStorageAvailable();
    if (isStoreAvailable) {
        sessionStorage.removeItem(key);
    }
    return isStoreAvailable;
};
export const getSessionStorageValue = (key, remove = false) => {
    let storedValue = null;
    if (isSessionStorageAvailable()) {
        storedValue = sessionStorage.getItem(key);
    }
    if (remove) {
        removeSessionStorageValue(key);
    }
    return storedValue;
};
export const setSessionStorageValue = (key, value) => {
    const isStoreAvailable = isSessionStorageAvailable();
    if (isStoreAvailable) {
        sessionStorage.setItem(key, value);
    }
    return isStoreAvailable;
};
const isLocalStorageAvailable = () => {
    let isAvailable = false;
    if (typeof localStorage !== undefined) {
        try {
            const key = 'isAvailable';
            const value = 'true';
            localStorage.setItem(key, value);
            isAvailable = localStorage.getItem(key) === value;
            localStorage.removeItem(key);
        }
        catch (e) {
            //TODO: Empty block
        }
    }
    return isAvailable;
};
export const removeLocalStorageValue = (key) => {
    const isStoreAvailable = isLocalStorageAvailable();
    if (isStoreAvailable) {
        localStorage.removeItem(key);
    }
    return isStoreAvailable;
};
export const getLocalStorageValue = (key, remove = false) => {
    let storedValue = null;
    if (isLocalStorageAvailable()) {
        storedValue = localStorage.getItem(key);
    }
    if (remove) {
        removeLocalStorageValue(key);
    }
    return storedValue;
};
export const setLocalStorageValue = (key, value) => {
    const isStoreAvailable = isLocalStorageAvailable();
    if (isStoreAvailable) {
        localStorage.setItem(key, value);
    }
    return isStoreAvailable;
};

export const displayText = {
    // Error Messages
    ERROR_MSG_404: "We can't find the page you're looking for.",
    ERROR_MSG_GENERIC: 'Hmm, something went wrong.',
    ERROR_MSG_VIN: "Oops! We're having trouble finding that vehicle.",
    ERROR_MSG_DROVE_OFF: 'Looks like that one drove off',
    ERROR_MSG_DEALER: "Looks like we couldn't find that page",
    // Error Suggestions
    ERROR_SUGGESTION_1: 'Please reach out to the dealership.',
    ERROR_SUGGESTION_2: 'Please try again later or reach out to the dealership.',
    ERROR_SUGGESTION_DROVE_OFF: 'Sorry, that car is out of stock now. In the meantime, we picked out similar options for you to checkout. You can also contact us with questions or for more options.',
    ERROR_SUGGESTION_DEALER: 'Navigate back to the home page to continue shopping for vehicles',
    ERROR_SUGGESTION_OUT_OF_STOCK_NO_SIMILAR: "Sorry, that car is out of stock now. Let's find another option from our inventory. You can also contact us with questions or for more options.",
    // CTA
    SEND_FEEDBACK: 'Send message',
    SEND_FEEDBACK_THANKS: 'Thanks',
    SEND_FEEDBACK_SUCCESS: `We'll be in touch with more info.`,
    SEND_FEEDBACK_FAILURE: 'Failed to send. Please try again later.',
    CTA_LIKE: "I'm interested",
    CTA_DISLIKE: 'Not for me',
    // Legal
    BMO_DISCLAIMER: "Pre-qualified pricing is based on a <b> dealer's preferred participating lender</b>. You may have other pre-qualified offers. Contact the dealer to discuss other financing options.",
    DEALER_ADVERTISED_PRICE: 'The price given by the dealer.  It may include offers and incentives that you may not qualify for. It may also exclude charges such as tax, title and license fees, and other fees. This price can change, and you should check with the dealer to see what is included or left out of the price shown.',
    FINANCE_DISCLOSURE_TEXT_TTL_DISABLED: 'These terms, including monthly payment and Annual Percentage Rate (APR) are estimates, and are not final until your financing is completed at <DEALER_NAME>. Estimated APRs are provided by a third party as examples only. The estimated APRs may not be available to all consumers within a particular credit score range, and are not an offer of credit. Estimated terms are subject to change. Rates may depend on your credit and key financing characteristics, including but not limited to the amount financed, term, loan-to-value ratio, down payment amount, and vehicle characteristics. An estimate of tax, title and license fees is not included in the Total Amount Financed, however you can opt to include TT&L in terms with the toggle above to see how including TT&L may impact your financing terms. The price may not include optional products like service contract or extended warranty. Other applicable products and fees may be required to purchase this vehicle.',
    FINANCE_DISCLOSURE_TEXT_TTL_ENABLED: 'These terms, including monthly payment and Annual Percentage Rate (APR) are estimates, and are not final until your financing is completed at <DEALER_NAME>. Estimated APRs are provided by a third party as examples only. The estimated APRs may not be available to all consumers within a particular credit score range, and are not an offer of credit. Estimated terms are subject to change. Rates may depend on your credit and key financing characteristics, including but not limited to the amount financed, term, loan-to-value ratio, down payment amount, and vehicle characteristics. You should verify with <DEALER_NAME> what fees are included in the dealer advertised price. An estimate of tax, title, and license fees are included in the Total Amount Financed to show you how they may impact your financing terms. Taxes and fees are based on the location of <DEALER_NAME>. The price may not include optional products like service contract or extended warranty. Other applicable products and fees may be required to purchase this vehicle.',
    LEASE_DISCLOSURE_TEXT: 'Displayed leasing terms, including rates and monthly payments, are estimates provided by' +
        ' the dealer or lenders associated with the dealer and are based on an estimated credit score.' +
        ' Such terms, along with displayed pricing and offers, are subject to change based on the' +
        ' information you provide to and receive from the dealer. The Dealer Price does not include' +
        ' estimated taxes, registration, title fees (including any fees associated with the dealer helping' +
        ' to secure your registration and/or title), costs of additional requests you make, or any' +
        ' other charges associated with leasing a vehicle. A security deposit will be required upon' +
        ' leasing, and is not accounted for in the estimated terms displayed. At the end of your lease a' +
        ' [DISPOSITION FEE]' +
        ' disposition fee may be due and miles above your selected yearly total miles will incur a' +
        ' [OVERAGE FEE]' +
        '/mile overage charge. Leasing terms are not final until your lease is completed at the' +
        " dealership, and your eligibility for such terms will be based on the dealer's or its" +
        " associated lender's evaluation of your credit application, which could include consideration" +
        ' of key credit characteristics, your income, the vehicle you are leasing, and the details of the' +
        ' deal. Inventory availability is subject to change without notice. Please contact the dealer' +
        ' to ensure the availability of the car you are considering and to check your eligibility for' +
        ' any leasing terms and displayed offers. Total of Scheduled Periodic Payments is' +
        ' $[LEASING TOTAL PAYMENT]' +
        '. This is the number of payments x monthly payment.'
};
